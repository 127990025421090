<template>
	<div class="pagination">
		<div class="pagination__info">
			<span class="pagination__list">
				<span class="pagination__page" v-for="p in pages" :key="p" :class="{ active: (p - 1) === page }" @click="changePage(p)">
					{{ p }}
				</span>
			</span>
		</div>
		<div class="pagination__ctas">
			<span class="pagination__cta" @click="changePage(1)">
				<i class="pi pi-angle-double-left"></i>
			</span>
			<span class="pagination__cta" @click="changePage(page - 1)">
				<i class="pi pi-angle-left"></i>
			</span>
			<span class="pagination__cta" @click="changePage(page + 1)">
				<i class="pi pi-angle-right"></i>
			</span>
			<span class="pagination__cta" @click="changePage(pages)">
				<i class="pi pi-angle-double-right"></i>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Pagination',
	props: {
		total: {
			type: Number,
			default: 0
		},
		page: {
			type: Number,
			default: 0
		},
		pages: {
			type: Number,
			default: 1
		},
		size: {
			type: Number,
			default: 0
		}
	},
	methods: {
		changePage(page) {
			if (page > 0 && page <= this.pages) {
				this.$emit('change', page);
			}
		}
	}
}
</script>
<style lang="scss">
.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: var(--gutter) 0;
	&__info {
		display: flex;
		align-items: center;
		width: 70%;
		&__total {
			margin-right: 1rem;
		}
	}
	&__list {
		display: flex;
		width: 100%;
	}
	&__page {
		@extend %flexCenterCenter;
		@include size(20px,20px);
		cursor: pointer;
		border-radius: 100%;
		@extend %text-12;
		padding: 0.2rem;
		&.active {
			background-color: $color-cerise;
			color: $color-white;
			pointer-events: none;
		}
	}
	&__ctas {
		display: flex;
		justify-content: flex-end;
		width: 30%;
	}
	&__cta {
		cursor: pointer;
		padding: 0.5rem;
		i {
			pointer-events: none;
		}
	}
}
</style>
