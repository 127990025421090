<template>
	<main id="createqr" class="createqr page">
		<PageLoading v-if="loading" :isloading="loading" />
		<span class="createqr__title">
			Crea un nuovo Link
		</span>
		<div class="createqr__form">
			<div class="createqr__types">
				<span class="createqr__label__text">Tipologia di link *</span>
				<label v-for="type in types" :key="type.id" :for="type.id" class="createqr__type" :class="{ 'disabled': type.disabled }">
					<input type="radio" class="createqr__type__input" name="linktype" :id="type.id" v-model="link.type" :value="type.id" />
					<span class="createqr__type__checked"></span>
					<span class="createqr__type__icon">
						<i :class="`pi pi-${type.icon}`"></i>
					</span>
					<span class="createqr__type__label" v-html="type.label"></span>
				</label>
				<small v-if="errors[0].show" class="createqr__error">
					{{ errors[0].message }}
				</small>
			</div>
			<label for="title" class="createqr__label">
				<span class="createqr__label__text">Titolo *</span>
				<input type="text" name="title" id="title" v-model="link.name" />
				<small v-if="errors[1].show" class="createqr__error">
					{{ errors[1].message }}
				</small>
			</label>
			<label for="url" class="createqr__label">
				<span class="createqr__label__text">Url *</span>
				<input type="text" name="url" id="url" v-model="link.data" />
				<small v-if="errors[2].show" class="createqr__error">
					{{ errors[2].message }}
				</small>
			</label>
			<span v-if="categories && categories.content && categories.content.length" for="categories" class="createqr__label half cats">
				<span class="createqr__label__text">Categorie</span>
				<Dropdown v-model="cats" :options="categories.content" optionLabel="category" :filter="true" placeholder="Seleziona la categoria" :showClear="true">
					<template #value="slotProps">
						<div class="p-dropdown-icon-value" v-if="slotProps.value">
							<i :class="`pi pi-${slotProps.value.icon}`"></i>
							<span>{{slotProps.value.name}}</span>
						</div>
						<span v-else>
            				{{slotProps.placeholder}}
						</span>
					</template>
					<template #option="slotProps">
						<div class="p-dropdown-icon-option">
							<i :class="`pi pi-${slotProps.option.icon}`"></i>
							<span>{{slotProps.option.name}}</span>
						</div>
					</template>
				</Dropdown>
			</span>
			<label for="tags" class="createqr__label tags" :class="{ 'half': (categories && categories.content && categories.content.length)}">
				<span class="createqr__label__text">Tags</span>
				<input type="text" name="tags" id="tags" maxlength="21" v-model="tag" @input="handleTag" />
				<small class="createqr__label__info">
					Sono ammesse lettere, numeri e il carattere "-", separare i tag con la virgola
				</small>
				<small v-if="tagerror" class="createqr__tagerror">
					Massimo 20 caratteri
				</small>
				<span v-if="link.tags && link.tags.length" class="createqr__tags">
					<span v-for="(tag, tagI) in link.tags" :key="tagI" class="tag">
						<span class="tag__label" v-html="tag"></span>
						<span class="tag__delete" @click="removeTag(tagI)"></span>
					</span>
				</span>
			</label>
			<div class="createqr__ctas">
				<span class="createqr__btn btn noborder" @click="cancelLink">
					Annulla
				</span>
				<span class="createqr__btn btn filled" @click="createLink">
					Crea
				</span>
			</div>
		</div>
	</main>
</template>
<script>

import Dropdown from "primevue/dropdown";
import PageLoading from "@/views/components/layout/PageLoading.vue";

export default {
	name: 'CreateQr',
	components: {PageLoading, Dropdown},
	data() {
		return {
			url: null,
			loading: false,
			link: {
				name: null,
				type: 'url',
				data: null,
				categories: null,
				tags: null,
			},
			tag: null,
			tagerror: false,
			categories: null,
			cats: null,
			types: [],
			errors: [
				{
					type: 'type',
					message: 'Seleziona una tipologia di link',
					show: false,
				},
				{
					type: 'name',
					message: 'Inserisci un titolo',
					show: false,
				},
				{
					type: 'data',
					message: 'Inserisci un link',
					show: false,
				},
			]
		}
	},
	watch: {
		tag: {
			immediate: false,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		handleTag() {
			if(this.tag && this.tag.length >= 3) {
				let t = this.tag.replace(/\s+/g, '-').split('');
				if(this.tag.length > 20) {
					this.tagerror = true;
				}
				if(t[this.tag.length - 1] == ',') {
					console.log('---', t)
					this.link.tags.push(t.slice(0,-1).join(''));
					this.tag = null;
				}
			}
		},
		cancelLink() {
			this.link = {
				name: null,
				type: null,
				data: null,
				categories: null,
				tags: null,
			}
			setTimeout(() => {
				window.location.href = '#/links';
			}, 100);
		},
		validateData() {
			if(!this.link.name || !this.link.type || !this.link.data) {
				return false;
			}
			return true;
		},
		createLink() {
			this.loading = true;
			if(this.cats && this.cats.id) {
				const c = this.link.categories.find(cat => cat === this.cats.id);
				if(!c) {
					this.link.categories.push(this.cats.id);
				}
			}
			console.log('LINK', this.link)
			let isDataValid = this.validateData();
			if(isDataValid) {
				this.$service.link.create(this.link).then(res => {
					this.loading = false;
					setTimeout(() => {
						window.location.href = '#/links';
					}, 100)
				}).catch(err => {
					console.error('ERROR', err)
					this.errors.forEach(er => {
						switch (er.type) {
							case 'data':
								if(!this.link.data) {
									er.show = true;
								}
								break;
							case 'type':
								if(!this.link.type) {
									er.show = true;
								}
								break;
							case 'name':
								if(!this.link.name) {
									er.show = true;
								}
								break;
						}
					})
					
					this.loading = false;
				})
			} else {
				this.errors.forEach(er => {
					switch (er.type) {
						case 'data':
							if(!this.link.data) {
								er.show = true;
							}
							break;
						case 'type':
							if(!this.link.type) {
								er.show = true;
							}
							break;
						case 'name':
							if(!this.link.name) {
								er.show = true;
							}
							break;
					}
				})
				
				this.loading = false;
				
			}
		},
		removeTag(tagIndex) {
			this.link.tags.splice(+tagIndex, 1);
		},
		init() {
			this.categories = [];
			this.link.categories = [];
			this.link.tags = [];
			
			this.handleTag();
			
			this.$service.cats.get().then(res => {
				this.categories = res;
			});
			
			this.types = [...this.$store.state.types];
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.createqr {
	&__title {
		@extend  %text-33;
		display: block;
		width: 100%;
		font-weight: 700;
		color: $color-gun;
		margin-bottom: var(--supergutter);
	}
	&__form {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	&__label {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
		&__text {
			@extend %text-12;
			display: block;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: var(--thirdgutter);
		}
		&__info {
			display: block;
			width: 100%;
			@extend %text-12;
			color: rgba($color-gun, 0.7);
			margin-bottom: var(--thirdgutter);
		}
		input {
			display: flex;
			align-items: center;
			@include size(100%,var(--input));
			background: $color-isabelline;
			padding: 0 var(--thirdgutter);
			border-radius: var(--radius);
			border: 0;
		}
	}
	&__tags {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		margin-top: var(--thirdgutter);
		.tag {
			@extend %flexCenterStart;
			width: max-content;
			padding: 6px 10px;
			background: $color-isabelline;
			border-radius: var(--radius);
			margin-right: 5px;
			margin-bottom: 5px;
			&__label {
				display: block;
				@extend %text-12;
				padding-right: 10px;
			}
			&__delete {
				display: block;
				@include size(10px,10px);
				position: relative;
				@extend %arrowClose;
				cursor: pointer;
				&:before,
				&:after {
					background: rgba($color-gun, 0.6);
					@include size(100%,1px);
					pointer-events: none;
				}
			}
		}
	}
	&__ctas {
		@extend %flexCenterEnd;
		width: 100%;
	}
	&__btn {
		&.filled {
			margin-left: var(--minigutter);
		}
	}
	.cats {
		.p-dropdown {
			&-items {
				display: block;
			}
			&-item {
				@extend %flexCenterStart;
				display: flex;
				width: 100%;
				padding: 6px 0;
				&:before { display: none; }
				.pi {
					position: relative;
				}
			}
		}
	}
	&__types {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: var(--gutter);
		.createqr__label__text {
			width: 100%;
		}
	}
	&__type {
		@extend %flexCenterStart;
		width: max-content;
		position: relative;
		margin-right: var(--gutter);
		cursor: pointer;
		&__checked {
			position: absolute;
			top: -5px;
			left: -10px;
			@include size(calc(100% + 25px),calc(100% + 10px));
			background: $color-bittersweet;
			border-radius: var(--radius);
			transition: all 0.3s;
			z-index: 0;
			opacity: 0;
		}
		&__input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}
		&__icon, &__label {
			pointer-events: none;
			z-index: 1;
			position: relative;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--gutter),var(--gutter));
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		.createqr__type__input:checked ~ .createqr__type {
			&__checked {
				opacity: 1;
			}
			&__icon, &__label {
				color: $color-white !important;
			}
		}
		.createqr__type__input:not(:checked) ~ .createqr__type__checked {
			opacity: 0;
		}
	}
	@media (min-width: $from-large) {
		&__label {
			&.half {
				width: 48%;
				margin-right: 2%;
			}
		}
		&__form {
			max-width: 600px;
		}
	}
}
</style>
