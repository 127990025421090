<template>
	<div v-if="qr" class="modal downloadqr">
		<div class="modal__box">
			<span class="modal__box__close" @click="cancelAction">
				<i class="pi pi-times"></i>
			</span>
			<div class="downloadqr__title">
				Scarica il tuo QR Code
			</div>
			<figure v-if="qr" class="downloadqr__media">
				<img class="downloadqr__media__image" :src="`${API_URL}/links/${qr.id}/qrcode`" alt="" />
			</figure>
			<div class="downloadqr__options">
				<span class="downloadqr__options__label">
					Scarica il formato che preferisci.
				</span>
				<a :href="`${API_URL}/links/${qr.id}/qrcode?format=svg&scale=10`" :download="`${qr.name.replace(/\s+/g, '-').toLowerCase()}_${qr.code}`" target="_blank" class="downloadqr__options__btn" @click="isDownloaded = true;">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--svg" width="100%" height="100%"></use>
					</svg>
				</a>
				<a :href="`${API_URL}/links/${qr.id}/qrcode?format=png&scale=10`" :download="`${qr.name.replace(/\s+/g, '-').toLowerCase()}_${qr.code}`" target="_blank" class="downloadqr__options__btn" @click="isDownloaded = true;">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--png" width="100%" height="100%"></use>
					</svg>
				</a>
				<a :href="`${API_URL}/links/${qr.id}/qrcode?format=jpg&scale=10`" :download="`${qr.name.replace(/\s+/g, '-').toLowerCase()}_${qr.code}`" target="_blank" class="downloadqr__options__btn" @click="isDownloaded = true;">
					<svg viewBox="0 0 50 50">
						<use xlink:href="#icon--jpg" width="100%" height="100%"></use>
					</svg>
				</a>
			</div>
			<div v-if="isDownloaded" class="downloadqr__ok">
				<span class="downloadqr__ok__title">
					Download Completato!
				</span>
				<span class="downloadqr__ok__text">
					Il tuo QR Code è stato scaricato correttamente.
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";

export default {
	name: 'DownloadQr',
	data() {
		return {
			API_URL: (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}',
			isDownloaded: false,
			qr: null,
		}
	},
	watch: {
		isDownloaded: {
			handler(newval, oldval) {
				console.log(newval)
				if(newval) {
					setTimeout(() => {
						this.isDownloaded = false;
					}, 3000);
				}
				return newval;
			}
		}
	},
	methods: {
		cancelAction() {
			this.qr = null;
		},
		init() {
			if(this.isDownloaded) {
			
			}
		}
	},
	mounted() {
		EventBus.$on('download-qr', item => {
			this.qr = item;
		})
		
		this.init();
	}
}
</script>

<style lang="scss">
.modal {
	@extend %modal;
	.downloadqr {
		&__ok {
			position: absolute;
			top: 0;
			left: 0;
			@extend %flexCenterCenter;
			flex-direction: column;
			@include size(100%,100%);
			background: rgba($color-white, 0.95);
			overflow: hidden;
			border-radius: var(--radius);
			z-index: 9;
			&__title {
				@extend %text-25;
				font-weight: 600;
				color: $color-cerise;
				margin-bottom: var(--minigutter);
			}
			&__text {
				@extend %text-16;
				color: $color-gun;
			}
		}
		&__title {
			display: block;
			width: 100%;
			@extend %text-25;
			font-weight: 600;
			margin-bottom: var(--minigutter);
			text-align: center;
		}
		&__media {
			display: block;
			width: 100%;
			max-width: 200px;
			margin: 0 auto;
		}
		&__options {
			@extend %flexCenterCenter;
			flex-wrap: wrap;
			width: 100%;
			text-align: center;
			&__label {
				@extend %text-12;
				display: block;
				width: 100%;
				text-transform: uppercase;
				margin-bottom: var(--minigutter);
			}
			&__btn {
				@extend %flexCenterCenter;
				@include size(80px,80px);
				border-radius: 100%;
				margin: 0 6px;
				transition: 180ms linear;
				cursor: pointer;
				svg {
					display: block;
					@include size(50px,50px);
					fill: $color-cerise;
				}
				&:hover {
					background: $color-cerise;
					svg {
						fill: $color-white;
					}
				}
			}
		}
	}
}
</style>
