<template>
	<div class="card">
		<figure class="card__media">
			<img class="card__media__image" :src="`${API_URL}/links/${card.id}/qrcode`" alt="" />
		</figure>
		<a :href="`#/links/edit/${this.card.id}`" class="card__link">
			<i class="pi pi-arrow-right"></i>
		</a>
		<span class="card__title" v-html="card.name"></span>
		<small v-if="card.categories && card.categories.length" class="card__cat">
			<span v-if="card.categories[0].icon" class="card__cat__icon">
				<i :class="`pi pi-${card.categories[0].icon}`" :style="`color: #${card.categories[0].color};`"></i>
			</span>
			<span class="card__cat__label" v-html="card.categories[0].name" :style="`color: #${card.categories[0].color};`"></span>
		</small>
		<span v-if="card.tags && card.tags.length" class="card__tags">
			<span class="tag" v-for="(tag, tagI) in card.tags" :key="tagI">
				<span class="tag__label" v-html="tag"></span>
			</span>
		</span>
		<div class="card__foot">
			<small v-if="card.type" class="card__foot__type">
				<i :class="`pi pi-${typeIcon}`"></i>
			</small>
			<span class="card__foot__ctas">
				<span class="card__foot__btn" @click="downloadQr">
					<i class="pi pi-download"></i>
				</span>
				<span class="card__foot__btn" @click="deleteLink">
					<i class="pi pi-trash"></i>
				</span>
			</span>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus';

export default {
	name: 'Card',
	props: {
		card: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			API_URL: (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}',
			typeIcon: null,
		}
	},
	methods: {
		downloadQr() {
			EventBus.$emit('download-qr', this.card);
		},
		deleteLink() {
			EventBus.$emit('confirm-delete-qr', this.card);
		},
		init() {
			console.log('--->>> ', this.card)
			this.$store.state.types.forEach(type => {
				if(type.id == this.card.type) {
					this.typeIcon = type.icon;
				}
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.card {
	display: block;
	width: 100%;
	border-radius: var(--radius);
	padding: var(--minigutter) var(--minigutter) calc(var(--gutter) * 1.5);
	max-width: 100%;
	box-shadow: 3px 3px 10px rgba($color-gun, 0.1);
	border: 1px solid $color-isabelline;
	transition: 250ms linear;
	position: relative;
	&__head {
		@extend %flexCenterEnd;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__ctas {
			@extend %flexCenterEnd;
			width: 30%;
		}
	}
	&__cat {
		@extend %flexCenterStart;
		width: 100%;
		margin-bottom: var(--minigutter);
		&__icon {
			@extend %flexCenterCenter;
			@include size(20px,20px);
			i {
				font-size: 15px;
			}
		}
		&__label {
			@extend %text-12;
			margin-left: var(--thirdgutter);
			line-height: 1.8;
		}
	}
	&__input {
		display: flex;
		align-items: center;
		@include size(18px,18px);
		&__fake {
			display: block;
			@include size(100%,100%);
			border-radius: 100%;
			border: 1px solid $color-silver;
			position: relative;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				background: $color-blue;
				@include size(62%,62%);
				border-radius: 100%;
				transform: translate(-50%,-50%) scale(0);
				transition: 180ms linear;
			}
		}
		&__input { display: none; }
		.card__input__input:checked ~ .card__input__fake {
			&:before {
				transform: translate(-50%,-50%) scale(1);
			}
		}
		.card__input__input:not(:checked) ~ .card__input__fake {
			&:before {
				transform: translate(-50%,-50%) scale(0);
			}
		}
	}
	&__media {
		display: block;
		width: var(--supergutter);
		margin-bottom: var(--gutter);
		&__image {
			display: block;
			width: 100%;
		}
	}
	&__link {
		position: absolute;
		top: var(--minigutter);
		right: var(--minigutter);
		@extend %flexCenterCenter;
		background: $color-cerise;
		@include size(var(--gutter),var(--gutter));
		border-radius: 100%;
		opacity: 0;
		transition: 180ms linear;
		i {
			color: $color-white;
		}
		&:hover {
			background: linear-gradient(45deg, $color-violet, $color-ultraviolet);
		}
	}
	&__title, &__data {
		display: block;
		width: 100%;
		color: $color-gun;
	}
	&__title {
		@extend %text-20;
		font-weight: 700;
		margin-bottom: var(--thirdgutter);
	}
	&__tags {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: var(--thirdgutter) 0;
		.tag {
			@extend %text-10;
			display: block;
			width: max-content;
			background: $color-isabelline;
			padding: 6px 10px;
			border-radius: var(--radius);
			margin: 0 5px 5px 0;
		}
	}
	&__data {

	}
	&__foot {
		position: absolute;
		bottom: 0;
		left: 0;
		@extend %flexCenterBetween;
		@include size(100%,calc(var(--gutter) * 1.5));
		margin-top: var(--gutter);
		padding: 0 var(--minigutter) var(--minigutter);
		&__type {
			@extend %flexCenterCenter;
			@include size(var(--gutter),var(--gutter));
			i {
				font-size: 20px;
				color: $color-silver;
			}
		}
		&__ctas {
			@extend %flexCenterEnd;
			width: calc(100% - var(--gutter));
			opacity: 0;
			transition: 250ms linear;
		}
		&__btn {
			@extend %flexCenterCenter;
			@include size(30px,30px);
			border-radius: 100%;
			cursor: pointer;
			transition: 180ms linear;
			padding: 10px;
			i {
				font-size: 15px !important;
				color: $color-gun !important;
			}
			@media (hover: hover) {
				&:hover {
					background: $color-violet;
					i {
						color: $color-white !important;
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		width: 30%;
		margin: 0 1% var(--minigutter);
	}
	@media (hover: hover) {
		&:hover {
			//background: $color-cerise;
			box-shadow: 0 0 20px rgba($color-gun, 0.2);
			.card {
				&__link {
					opacity: 1;
				}
			}
			.card__foot__ctas {
				opacity: 1;
			}
		}
	}
	@media (max-width: $to-large) {
		&__foot {
			&__ctas {
				opacity: 1;
			}
		}
	}
}
</style>
