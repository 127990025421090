<template>
	<main v-if="link" id="editqr" class="editqr page">
		<PageLoading v-if="loading" :isLoading="loading" />
		<div class="editqr__head">
			<span class="editqr__head__title">
				Modifica il Link
			</span>
			<span class="editqr__head__ctas">
				<span class="editqr__btn btn noborder" @click="cancelLink">
					Annulla
				</span>
					<span class="editqr__btn btn filled" @click="editLink">
					Modifica
				</span>
			</span>
		</div>
		<div class="editqr__intro">
			<div class="editqr__form">
				<div class="editqr__types">
					<label v-for="type in types" :key="type.id" :for="type.id" class="createqr__type" :class="{ 'disabled': type.disabled }">
						<input type="radio" class="createqr__type__input" name="linktype" :id="type.id" v-model="link.type" :value="type.id" />
						<span class="createqr__type__checked"></span>
						<span class="createqr__type__icon">
						<i :class="`pi pi-${type.icon}`"></i>
					</span>
						<span class="createqr__type__label" v-html="type.label"></span>
					</label>
				</div>
				<label for="title" class="editqr__label">
					<span class="editqr__label__text">Titolo</span>
					<input type="text" name="title" id="title" v-model="link.name" />
				</label>
				<label for="url" class="editqr__label">
					<span class="editqr__label__text">Url</span>
					<input type="text" name="url" id="url" v-model="link.data" />
				</label>
				<template v-if="(link.categories && link.categories.length) || (categories && categories.content && categories.content.length)">
					<span for="categories" class="editqr__label half cats">
						<template v-if="link.categories && link.categories.length">
							<span class="editqr__label__text">Categorie</span>
							<Dropdown v-model="cats" :options="categories.content" optionLabel="category" :filter="true" placeholder="Seleziona la categoria" :value="link.categories[0].name" :showClear="true">
								<template #value="slotProps">
									<div class="p-dropdown-icon-value" v-if="slotProps.value">
										<i :class="`pi pi-${slotProps.value.icon}`"></i>
										<span>{{slotProps.value.name}}</span>
									</div>
									<span v-else>
										{{slotProps.placeholder}}
									</span>
								</template>
								<template #option="slotProps">
									<div class="p-dropdown-icon-option">
										<i :class="`pi pi-${slotProps.option.icon}`"></i>
										<span>{{slotProps.option.name}}</span>
									</div>
								</template>
							</Dropdown>
						</template>
						<template v-else-if="categories && categories.content && categories.content.length">
							<span class="editqr__label__text">Categorie</span>
							<Dropdown v-model="cats" :options="categories.content" optionLabel="category" :filter="true" placeholder="Seleziona la categoria" :showClear="true">
								<template #value="slotProps">
									<div class="p-dropdown-icon-value" v-if="slotProps.value">
										<i :class="`pi pi-${slotProps.value.icon}`"></i>
										<span>{{slotProps.value.name}}</span>
									</div>
									<span v-else>
										{{slotProps.placeholder}}
									</span>
								</template>
								<template #option="slotProps">
									<div class="p-dropdown-icon-option">
										<i :class="`pi pi-${slotProps.option.icon}`"></i>
										<span>{{slotProps.option.name}}</span>
									</div>
								</template>
							</Dropdown>
						</template>
					</span>
				</template>
				<label for="tags" class="editqr__label tags" :class="{ 'half': (categories && categories.content && categories.content.length)}">
					<span class="editqr__label__text">Tags</span>
					<input type="text" name="tags" id="tags" maxlength="21" v-model="tag" @input="handleTag" />
					<small class="editqr__label__info">
						Sono ammesse lettere, numeri e il carattere "-", separare i tag con la virgola
					</small>
					<small v-if="tagerror" class="editqr__tagerror">
						Massimo 20 caratteri
					</small>
					<span v-if="link.tags && link.tags.length" class="editqr__tags">
					<span v-for="(tag, tagI) in link.tags" :key="tagI" class="tag">
						<span class="tag__label" v-html="tag"></span>
						<span class="tag__delete" @click="removeTag(tagI)"></span>
					</span>
				</span>
				</label>
				<div class="editqr__ctas">
					<span class="editqr__btn btn noborder" @click="cancelLink">
						Annulla
					</span>
						<span class="editqr__btn btn filled" @click="editLink">
						Modifica
					</span>
				</div>
			</div>
			<div class="editqr__preview">
				<figure class="editqr__preview__media">
					<img class="editqr__preview__media__image" :src="`${API_URL}/links/${link.id}/qrcode`" alt="" />
				</figure>
				<span class="editqr__download" @click="downloadQr">
					<span class="editqr__download__icon">
						<i class="pi pi-download"></i>
					</span>
					<span class="editqr__download__label">
						Scarica
					</span>
				</span>
				<TotalOpenings v-if="totalOpenings" :info="totalOpenings" />
			</div>
		</div>
		<div class="editqr__stats">
			<template v-if="link.id">
				<OpeningOverTime :linkid="link.id" class="fullrow" />
				<Referers :linkid="link.id" />
				<Countries :linkid="link.id" />
			</template>
		</div>
		<div class="editqr__foot">
			<span class="editqr__delete" @click="deleteLink">
				<span class="editqr__delete__label">
					Elimina il link
				</span>
				<i class="pi pi-trash"></i>
			</span>
		</div>
	</main>
</template>
<script>
import {EventBus} from "@/events_bus";
import Dropdown from "primevue/dropdown";
import TotalOpenings from '@/views/components/stats/TotalOpenings';
import OpeningOverTime from "@/views/components/stats/OpeningOverTime.vue";
import Referers from "@/views/components/stats/Referers.vue";
import Countries from "@/views/components/stats/Countries";
import PageLoading from "@/views/components/layout/PageLoading.vue";

export default {
	name: 'CreateQr',
	components: {
		PageLoading,
		Referers,
		OpeningOverTime,
		Dropdown,
		TotalOpenings,
		Countries
	},
	data() {
		return {
			API_URL: (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}',
			url: null,
			loading: false,
			link: {
				name: null,
				type: null,
				data: null,
				categories: null,
				tags: null,
			},
			tag: null,
			tagerror: false,
			categories: null,
			cats: null,
			types: [],
			totalOpenings: null,
			referers: null,
		}
	},
	watch: {
		tag: {
			immediate: false,
			handler(newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		downloadQr() {
			EventBus.$emit('download-qr', this.link);
		},
		handleTag() {
			if(this.tag && this.tag.length >= 3) {
				let t = this.tag.replace(/\s+/g, '-').split('');
				if(this.tag.length > 20) {
					this.tagerror = true;
				}
				if(t[this.tag.length - 1] == ',') {
					console.log('---', t)
					this.link.tags.push(t.slice(0,-1).join(''));
					this.tag = null;
				}
			}
		},
		deleteLink() {
			EventBus.$emit('confirm-delete-qr', this.link);
		},
		cancelLink() {
			console.log('CANCEL');
			this.link = {
				name: null,
				type: null,
				data: null,
				categories: null,
				tags: null,
			}
			setTimeout(() => {
				window.location.href = '#/links';
			}, 100);
		},
		editLink() {
			this.loading = true;
			this.link.categories = [];
			console.log('CATS', this.cats);
			if(this.cats && this.cats.id) {
				this.link.categories.push(this.cats.id);
			}
			this.link.type = 'url';
			console.log('LINK', this.link)
			this.$service.link.edit(this.link.id, this.link).then(res => {
				console.log('--->>> ', res)
				setTimeout(() => {
					window.location.href = '#/links';
				}, 100)
			})
		},
		removeTag(tagIndex) {
			this.link.tags.splice(+tagIndex, 1);
		},
		init() {
			this.categories = [];
			this.link.categories = [];
			this.link.tags = [];
			
			this.handleTag();
			
			this.types = [...this.$store.state.types];
			
			this.$service.link.getSingle(this.$route.params.id).then(res => {
				console.log('LINK', res)
				this.link = res;
				if(this.link && this.link.id) {
					if(this.link.categories && this.link.categories.length) {
						this.cats = this.link.categories[0];
					}
					this.$service.stats.totalOpening(this.link.id).then(res => {
						this.totalOpenings = res;
					});
				}
			});
			
			this.$service.cats.get().then(res => {
				this.categories = res;
			});
			
		}
	},
	mounted() {
		this.init();
		
		EventBus.$on('update-overtime', () => {
		
		})
	}
}
</script>
<style lang="scss">
.editqr {
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--supergutter);
		&__title {
			@extend  %text-33;
			display: block;
			width: 100%;
			font-weight: 700;
			color: $color-gun;
		}
		&__ctas {
			@extend %flexCenterEnd;
			width: 50%;
			display: none;
		}
	}
	&__intro {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: var(--gutter);
	}
	&__form,
	&__preview,
	&__stats {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.statpanel {
			width: 100%;
		}
	}
	&__label {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
		&__text {
			@extend %text-12;
			display: block;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: var(--thirdgutter);
		}
		&__info {
			display: block;
			width: 100%;
			@extend %text-12;
			color: rgba($color-gun, 0.7);
			margin-bottom: var(--thirdgutter);
		}
		input {
			display: flex;
			align-items: center;
			@include size(100%,var(--input));
			background: $color-isabelline;
			padding: 0 var(--thirdgutter);
			border-radius: var(--radius);
			border: 0;
		}
	}
	&__tags {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		margin-top: var(--thirdgutter);
		.tag {
			@extend %flexCenterStart;
			width: max-content;
			padding: 6px 10px;
			background: $color-isabelline;
			border-radius: var(--radius);
			margin-right: 5px;
			margin-bottom: 5px;
			&__label {
				display: block;
				@extend %text-12;
				padding-right: 10px;
			}
			&__delete {
				display: block;
				@include size(10px,10px);
				position: relative;
				@extend %arrowClose;
				cursor: pointer;
				&:before,
				&:after {
					background: rgba($color-gun, 0.6);
					@include size(100%,1px);
					pointer-events: none;
				}
			}
		}
	}
	&__ctas {
		@extend %flexCenterEnd;
		width: 100%;
	}
	&__btn {
		&.filled {
			margin-left: var(--minigutter);
		}
	}
	.cats {
		.p-dropdown {
			&-items {
				display: block;
			}
			&-item {
				@extend %flexCenterStart;
				display: flex;
				width: 100%;
				padding: 6px 0;
				&:before { display: none; }
				.pi {
					position: relative;
				}
			}
		}
	}
	&__types {
		@extend %flexCenterStart;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: var(--gutter);
	}
	&__type{
		@extend %flexCenterStart;
		width: max-content;
		position: relative;
		margin-right: var(--gutter);
		cursor: pointer;
		&__checked {
			position: absolute;
			top: -5px;
			left: -10px;
			@include size(calc(100% + 25px),calc(100% + 10px));
			background: $color-isabelline;
			border-radius: var(--radius);
			transition: all 0.3s;
			z-index: 0;
			opacity: 0;
		}
		&__input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}
		&__icon, &__label {
			pointer-events: none;
			z-index: 1;
			position: relative;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--gutter),var(--gutter));
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
		.createqr__type__input:checked ~ .createqr__type__checked {
			opacity: 1;
		}
		.createqr__type__input:not(:checked) ~ .createqr__type__checked {
			opacity: 0;
		}
	}
	&__preview {
		&__media {
			display: block;
			width: 80%;
			margin: var(--gutter) auto;
			position: relative;
			&__image {
				display: block;
				width: 100%;
			}
		}
	}
	&__download {
		@extend %flexCenterStart;
		width: max-content;
		border-radius: var(--radius);
		margin: var(--gutter) auto var(--minigutter);
		cursor: pointer;
		&__icon {
			@extend %flexCenterCenter;
			@include size(20px,20px);
			margin-right: var(--thirdgutter);
		}
		& * {
			pointer-events: none;
			color: $color-gun;
			transition: 180ms linear;
		}
		&:hover {
			& * {
				color: $color-cerise;
			}
		}
	}
	.fullrow {
		width: 100% !important;
	}
	&__foot {
		@extend %flexCenterEnd;
		width: 100%;
		margin-top: var(--supergutter);
		margin-bottom: var(--gutter);
	}
	&__delete {
		@extend %flexCenterEnd;
		color: $color-cerise;
		cursor: pointer;
		i {
			display: block;
			margin-left: 10px;
		}
	}
	@media (min-width: $from-large) {
		&__head {
			&__title {
				width: 50%
			}
			&__ctas {
				display: flex;
			}
		}
		&__ctas {
			display: none;
		}
		&__label {
			&.half {
				width: 48%;
				margin-right: 2%;
			}
		}
		&__form {
			width: 70%;
		}
		&__preview {
			width: 30%;
			height: max-content;
			padding-left: var(--minigutter);
			&__media {
				width: 90%;
				margin: 0 auto;
			}
		}
		&__stats {
			justify-content: space-between;
			.statpanel {
				width: 49%;
				&.totalopenings {
					width: 30%;
				}
			}
		}
	}
}
</style>
